import React, { useState, useEffect } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineLinkedin,
} from "react-icons/ai";
import { footerNLinks, footerNLinks2 } from "../../static/data";
import logo2 from '../../Assests/images/logo-light.png';
import { Link } from "react-router-dom";
import { server } from "../../server";
import axios from "axios";
import { useSelector } from "react-redux";



const Footer = () => {
    const { seller, handle } = useSelector((state) => state.seller);        
  
  console.log("dataTU is: ");
  console.log(handle);

  
  return (
    <div className="bg-[#012] text-white">      
      
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <div className="flex items-center mt-[15px] mb-6">
           <Link to={handle && handle.facebook} target="_blank"> <AiFillFacebook size={25} className="cursor-pointer" /></Link>
            <Link to={handle && handle.linkedin} target="_blank">< AiOutlineLinkedin
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
            <Link to={handle && handle.instagram} target="_blank"><AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
            <Link to={handle && handle.youtube} target="_blank"><AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
            {seller && <Link to={`/edit-handle/${handle && handle._id}`}><h5 className="ml-3 text-[yellow] cursor-pointer">Edit</h5></Link>}
           </div>

           {footerNLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
          </ul>

          <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold mt-3">Products</h1>
          {footerNLinks2.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <p className="mt-3">Nigeria Office:</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">No.2 Ordu Avenue,</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Power-Encounter Junction</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Off East West Road</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Rumuodara, Port Harcourt</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Tel: +2348131131208, +2349025729875</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">
          E-mail: info@ohkoil.com
          </p>
          </ul>

          <ul className="text-center sm:text-start">
          <p className="mt-3">Warehouse</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">60 Ordinance Road,</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Trans Amadi</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Port Harcourt</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Rivers State</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Nigeria.</p>
          </ul>

          <ul className="text-center sm:text-start">
          <p className="mt-3">United Kingdom</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">51 Daintry Close,</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Harrow</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Middlesex,</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">United Kingdom</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">Tel: +447435800781</p>
          <p className="text-gray-400 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6">
          E-mail: info@ohkoil.com
          </p>

          </ul>

        
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 
         text-center pt-2 text-gray-400 text-sm gap-6 sm:px-8 px-5 pb-8"
      >
        <span>© 2025 Ocean Hawk Oil Resources Ltd.</span>

        <div className="sm:block flex items-center justify-center w-full mb-3 pb-4">
        <div>
            <Link to="/">
              <img
                src={logo2}
                width='80px'
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;


